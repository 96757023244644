export function toBool(value: string | boolean) {
	return value === 'true' || value === true;
}

export function toNumber(value: string | number) {
	if (typeof value === 'number') {
		return value;
	}

	const parsedNumber = parseInt(value, 10);
	if (!isNaN(parsedNumber)) {
		return parsedNumber;
	}

	return null;
}
