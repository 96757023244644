import { toBool, toNumber } from "./utils/string-converter";
import * as pkg from '../package.json';

const {hostname} = window.location;

if (!window.__CONFIG__) {
  window.__CONFIG__ = {};
}

let _DATA_BASE_URL = window.__CONFIG__.REACT_APP_RISE_DISPLAY_DATA_BASE_URL ?? 'https://api.nikesportpulse.com/';
let _IS_ANALYTICS_ENABLED = toBool(window.__CONFIG__.REACT_APP_RISE_DISPLAY_IS_ANALYTICS_ENABLED);
let _ANALYTICS_BASE_URL = window.__CONFIG__.REACT_APP_RISE_DISPLAY_ANALYTICS_BASE_URL ?? '';
let _WSS_HOST = window.__CONFIG__.REACT_APP_RISE_DISPLAY_WSS_HOST ?? '';
let _CLOUDINARY_DOMAIN = window.__CONFIG__.REACT_APP_RISE_DISPLAY_CLOUDINARY_DOMAIN ?? 'res.cloudinary.com';
let _CLOUDINARY_CLOUD_NAME = window.__CONFIG__.REACT_APP_RISE_DISPLAY_CLOUDINARY_CLOUD_NAME ?? 'jbboerner';
let _NIKE_QR_BASE_URL = window.__CONFIG__.REACT_APP_RISE_DISPLAY_NIKE_QR_BASE_URL ?? 'https://nikeqr.io';
let _SENTRY_DSN = window.__CONFIG__.REACT_APP_RISE_DISPLAY_SENTRY_DSN;
let _WEBSOCKET_URL = window.__CONFIG__.REACT_APP_WEBSOCKET_URL ?? "wss://ws.development.nikesportpulse.com/";
const _HEARTBEATS_DELAY = toNumber(window.__CONFIG__.REACT_APP_RISE_DISPLAY_HEARTBEATS_DELAY) ?? 900;

(() => {
  if (
    hostname.indexOf('qa-cn--hyperlive.netlify.app') >= 0 ||
    hostname.indexOf('qa-cn--rise-displays.netlify.app') >= 0 ||
    hostname.indexOf('qa-wof-cn--hyperlive.netlify.app') >= 0 ||
    hostname.indexOf('qa-wof-cn--rise-displays.netlify.app') >= 0
  ) {
    _DATA_BASE_URL = 'https://api.sportpulse.nike.com.cn/';
    _IS_ANALYTICS_ENABLED = false;
    _ANALYTICS_BASE_URL = 'https://api.analytics.sportpulse.nike.com.cn/';
    _CLOUDINARY_DOMAIN = 'static.nike.com.cn';
    _CLOUDINARY_CLOUD_NAME = 'sportpulse';
    _SENTRY_DSN = 'https://fccaf7b492d94e11808b3c06a69267a5@o926465.ingest.sentry.io/5899632';
  } else if (
    hostname.indexOf('legacy--hyperlive.netlify.app') >= 0 ||
    hostname.indexOf('serena--hyperlive.netlify.app') >= 0 ||
    hostname.indexOf('qa-prd--hyperlive.netlify.app') >= 0 ||
    hostname.indexOf('typescript--hyperlive.netlify.app') >= 0 ||
    hostname.indexOf('westlondon--hyperlive.netlify.app') >= 0 ||
    hostname.indexOf('qa-prd--rise-displays.netlify.app') >= 0 ||
    hostname.indexOf('qa-wof-prd--rise-displays.netlify.app') >= 0
  ) {
    _DATA_BASE_URL = 'https://api.nikesportpulse.com/';
    _IS_ANALYTICS_ENABLED = false;
    _ANALYTICS_BASE_URL = 'https://api.analytics.nikesportpulse.com/';
    _WSS_HOST = 'wss://qyak8lou6e.execute-api.us-east-1.amazonaws.com/Prod';
    _SENTRY_DSN = 'https://fccaf7b492d94e11808b3c06a69267a5@o926465.ingest.sentry.io/5899632';
    _WEBSOCKET_URL = 'wss://ws.nikesportpulse.com/';
  } else if (
    hostname.indexOf('qa-takeover--hyperlive.netlify.app') >= 0 ||
    hostname.indexOf('qa-takeover--rise-displays.netlify.app') >= 0
  ) {
    _DATA_BASE_URL = 'https://api.development.nikesportpulse.com/';
    _IS_ANALYTICS_ENABLED = false;
    _ANALYTICS_BASE_URL = 'https://api.development.analytics.nikesportpulse.com/';
    _WSS_HOST = 'wss://qyak8lou6e.execute-api.us-east-1.amazonaws.com/Prod';
    _SENTRY_DSN = 'https://fccaf7b492d94e11808b3c06a69267a5@o926465.ingest.sentry.io/5899632';
    _WEBSOCKET_URL = 'wss://ws.development.nikesportpulse.com/';
  } else if (hostname.indexOf('netlify.app') >= 0) {
    _DATA_BASE_URL = 'https://api.staging.nikesportpulse.com/';
    _IS_ANALYTICS_ENABLED = false;
    _ANALYTICS_BASE_URL = 'https://api.analytics.staging.nikesportpulse.com/';
    _WSS_HOST = 'wss://1cwj8i9uai.execute-api.us-east-1.amazonaws.com/Prod';
    _SENTRY_DSN = 'https://fccaf7b492d94e11808b3c06a69267a5@o926465.ingest.sentry.io/5899632';
  }
})();

const enableLocalStorageCache = () => {
  const {
    host,
    pathname
  } = window.location;

  return host.includes('displays')
    || host.includes('1234')
    || pathname.split('/')[1] === 'touchpoint';
};

export const SEND_HEARTBEAT_INTERVAL_SECONDS = 20_000;
export const DISABLE_SEND_HEARTBEATS_QUERY_PARAM_KEY = 'disable-send-heartbeats';
export const VERSION = pkg.version;
export const ENABLED_DISPLAYS_LOCAL_STORAGE_CACHE = enableLocalStorageCache();
export const DATA_BASE_URL = _DATA_BASE_URL;
export const WSS_HOST = _WSS_HOST;
export const IS_ANALYTICS_ENABLED = _IS_ANALYTICS_ENABLED;
export const ANALYTICS_BASE_URL = _ANALYTICS_BASE_URL;
export const CLOUDINARY_DOMAIN = _CLOUDINARY_DOMAIN;
export const CLOUDINARY_CLOUD_NAME = _CLOUDINARY_CLOUD_NAME;
export const NIKE_QR_BASE_URL = _NIKE_QR_BASE_URL;
export const SENTRY_DSN = _SENTRY_DSN;
export const WEBSOCKET_URL = _WEBSOCKET_URL;
export const HEARTBEATS_DELAY = _HEARTBEATS_DELAY;
